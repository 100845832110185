import React, { useEffect } from "react";

import { HomePage } from "./Home";
import { AboutPage } from "./About";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";

import "../assets/css/design.min.css";
import "../assets/css/icons.css";
import "../assets/css/font-awesome.css";
import { ResumePage } from "./Resume";
import { ContactPage } from "./Contact";

const IndexPage = () => {
  useEffect(() => {
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);
  return (
    <>
      <TopNavbar />
      <div className="main">
        <HomePage />
        <AboutPage />
        <ResumePage/>
        <ContactPage/>
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
