
export const ABOUT_START = "Hello!";
export const ABOUT_START2 = "I am ";
export const ABOUT_TITLE = "Full stack software developer";
export const ABOUT_LOCATION = "based in Canada";
export const _BUL = "• ";
export const ABOUT_NAME = "Abas Olfat";
export const ABOUT_SUMMERY =
  "I'm an experienced Software Engineer with solid expertise in designing, developing, debugging, and optimizing backend and frontend software applications, I'm proficient in C#, ASP.NET, javascript, React, SQL databases, " +
  "using Cloud computing platforms (Azure, AWS). I am always interested in learning new technologies and have experience working with small and large teams across multiple projects and companies.";

export const ABOUT_SERVICES = [
  "Create modern, scalable and performant software applications for your unique needs, including front-end & back-end technology using React and C# .NET technologies",
  "Build reliable microservices and restful back-end APIs   that support for web and mobile apps",
  "Optimize your web and desktop applications for maximum speed and scalability, identifying and resolving performance bottlenecks",
  "Manage your application components on cloud platforms.",
];
export const ABOUT_TYPE_ANIMATION_DELAY = 4000;
export const FOOTER_TEXT="Designed & Developed by Abas Olfat © 2024";
export const NAVBAR_LINKS_EMAIL="mailto:abas.olfat@yahoo.com";
export const NAVBAR_LINKS_GIT="https://github.com/abas1200";
export const NAVBAR_LINKS_LINKDIN="https://www.linkedin.com/in/abas-olfat-4632b7124/";

/////////////////////////////////////Settings//////////////////////////////////////////
export const NAVBAR_MENU_ITEMS = [
  {
    name: "home",
    text: "Home",
  },
  {
    name: "about",
    text: "About",
  },
  //   {
  //     name: "skills",
  //     text: "Skills",
  //   },
  {
    name: "resume",
    text: "Resume",
  },
  //   {
  //     name: "works",
  //     text: "Works",
  //   },
  {
    name: "contact",
    text: "Contact",
  },
];

export const SKILL_ITEMS = [
  {
    text: "C# .Net",
    url: "skills-icons/c-sharp.svg",
  },
  {
    text: "ASP.NET MVC/Core",
    url: "skills-icons/aspdotnet.svg",
  },
  {
    text: "ReactJs/TypeScript",
    url: "skills-icons/react.svg",
  },
  {
    text: "JavaScript",
    url: "skills-icons/javascript.svg",
  },
  {
    text: "NodeJS",
    url: "skills-icons/nodejs.svg",
  },
  {
    text: "SQL Server Database",
    url: "skills-icons/sql-database-generic.svg",
  },
  {
    text: "Docker",
    url: "skills-icons/docker.svg",
  },
  {
    text: "Microsoft Azure",
    url: "skills-icons/microsoft-azure.svg",
  },
  {
    text: "Amazon AWS",
    url: "skills-icons/aws.svg",
  },
];

export const SKILLS_PROGRESS = [
  {
    title: "C#/ASP.NET Core/MVC",
    percent: "100",
  },
  {
    title: "ReactJS/TypeScript/NodeJs/JQuery/JavaScript",
    percent: "90",
  },
  {
    title: "HTML/CSS/Bootstrap",
    percent: "90",
  },
  {
    title: "T-SQL/SQLSERVER/MongoDB/PostgreSQL/MYSQL",
    percent: "90",
  },
  {
    title: "Azure/AWS/Docker",
    percent: "85",
  },
];

export const RESUME_EDUCATION = [
  {
    title: "Master of Engineering (MEng) Information System Security",
    description:"GPA - (3.76/4.3)",
    organization: "Concordia University",
    link:"https://www.concordia.ca/",
    start: "2019",
    end: "2021",
    location:"Montreal, Quebec, Canada"
  },
  {
    title: "Bachelor's Degree in Computer Software Engineering",
    description:"",
    organization: "Razi University",
    link:"https://en.razi.ac.ir/",
    start: "2008",
    end: "2012",
    location:"Iran"
  },
];

export const RESUME_EXPERIENCE = [
  {
    title: "Full Stack Developer (.NET, React)",
    description:"Designed and Implemented high-performance cloud based web applications",
    organization: "Nuvei Technologies",
    link:"https://nuvei.com/",
    start: "05/2021",
    end: "09/2023",
    location:"Montreal, Quebec, Canada"
  },
  {
    title: "Senior Full Stack .NET Developer",
    description:"Designed and Implemented custom web and desktop applications based on user requests",
    organization: "DPardazan Novin & PSoft",
    link:"https://darupardazan.com/",
    start: "01/2013",
    end: "03/2019",
    location:"Tehran, Iran"
  }
];