import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import * as Constants from "../constants/index";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const VerticalElement = ({ data, icon }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#31416B",
        color: "#fff",
        boxShadow: "0 1px 20px 0px rgba(0, 0, 0, 0.1)",
      }}
      contentArrowStyle={{ borderRight: "7px solid  #31416B" }}
      date={`${data.start} - ${data.end}`}
      iconStyle={{ fontSize: "25px", background: "#31416B", color: "#fff" }}
      icon={icon}
    >
      <p className="vertical-timeline-element-title">
        <strong>{data.title}</strong>
      </p>
      <h4 className="vertical-timeline-element-subtitle">
      {data.link? <a href={data.link} target="_blank" >{data.organization}</a> :data.organization}
      </h4>
      <p>{data.description}</p>
      <p>{data.location}</p>
    </VerticalTimelineElement>
  );
};

const Timeline = ({ list, icon }) => {
  return (
    <VerticalTimeline layout="1-column-left">
      {list.map((data, index) => (
        <VerticalElement
          key={`timeline-item-${index}`}
          data={data}
          icon={
            <div style={{ marginTop: "3px", marginLeft: "5px" }}>{icon}</div>
          }
        />
      ))}
    </VerticalTimeline>
  );
};

export const ResumePage = () => {
  return (
    <div className="section section-pagination" id="resume-section">
      <img
        alt="..."
        className="path"
        src={require("../assets/img/path4.png")}
      />
      <img
        alt="..."
        className="path path1"
        src={require("../assets/img/path5.png")}
      />
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Experience
              </small>
            </div>

            <Timeline
              list={Constants.RESUME_EXPERIENCE}
              icon={<i className="fa-solid fa-business-time"></i>}
            />
          </Col>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Education
              </small>
            </div>
            <Timeline
              list={Constants.RESUME_EDUCATION}
              icon={<i className="fa-solid fa-graduation-cap" />}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
