import React from "react";
import { Container } from "reactstrap";
import Typewriter from "typewriter-effect";
import * as Constants from "../constants/index";

export const HomePage = () => {
  return (
    <div className="page-header" id="home-section">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand">
          <h1 className="h1-seo">{Constants.ABOUT_NAME}</h1>
          <h3 className="d-none d-sm-block">
            <Typewriter
              options={{
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString(Constants.ABOUT_TITLE)
                  .pauseFor(Constants.ABOUT_TYPE_ANIMATION_DELAY)
                  .deleteAll()
                  .start()
                  .typeString(Constants.ABOUT_LOCATION)
                  .pauseFor(Constants.ABOUT_TYPE_ANIMATION_DELAY);
              }}
            />
          </h3>
        </div>
      </Container>
    </div>
  );
};
