import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  Progress
} from "reactstrap";
import * as Constants from "../constants/index";
import useIsVisible from "../utils/useIsVisible";

export const AboutPage = () => {
  const skillsRef = useRef(null);
  const [runAnimation,setRunAnimation] = useState(false);
  const isSkillsVisible = useIsVisible(skillsRef);

  useEffect(() => {
    if(!runAnimation)
       setRunAnimation(isSkillsVisible);

  }, [isSkillsVisible]);
 
  return (
    <div className="section section-basic" id="about-section">
      <img
        alt="..."
        className="path"
        style={{maxHeight:"750px"}}
        src={require("../assets/img/path1.png")}
      />
      <Container>
        <Row className="ml-auto mr-auto">
          <div className="mb-3">
            <small className="text-uppercase font-weight-bold">
              Know Me More
            </small>
          </div>
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <div className="typography-line">
                <p>{Constants.ABOUT_SUMMERY}</p>
              </div>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                What Can I Do?
              </small>
            </div>

            <Card>
              <CardHeader> </CardHeader>
              <CardBody>
                <ul>
                  {Constants.ABOUT_SERVICES.map((item, index) => (
                    <li className="typography-line" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="container-skills">
                  {Constants.SKILL_ITEMS.map((item, index) => (
                    <div
                      style={{ display: "inline-block" }}
                      key={`sub-${index}`}
                    >
                      <img
                        className="img-skills"
                        id={`skill-${index}`}
                        key={`skill-icons-${index}`}
                        src={item.url}
                        alt={item.text}
                      />
                      <UncontrolledTooltip
                        key={`tooltip-${index}`}
                        placement="bottom"
                        target={`skill-${index}`}
                      >
                        {item.text}
                      </UncontrolledTooltip>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                My Skills
              </small>
            </div>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <div ref={skillsRef}>
                  {Constants.SKILLS_PROGRESS.map((item, index) => (
                    <div
                      key={index}
                      className="progress-container progress-info"
                    >
                      <span className="progress-badge">{item.title}</span>
                      <Progress
                        className={runAnimation ? "progress-run" : ""}
                        max="100"
                        value={item.percent}
                      >
                        <span className="progress-value">{`${item.percent}%`}</span>
                      </Progress>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
            <Col className="text-center">
              <Button
                className="btn-round"
                color="info"
                href="#"
                role="button"
                size="lg"
              >
                <i className="tim-icons icon-cloud-download-93" /> Download CV
              </Button>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
