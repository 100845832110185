import React, { useState, useRef } from "react";
import classnames from "classnames";
import axios from "axios";
import ReactLoading from "react-loading";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY, API_ADDRESS } from "../constants/secrets";

export const ContactPage = () => {
  const [fullNameFocus, setFullNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [messageFocus, setMessagectFocus] = useState(false);
  const [captchaFocus, setCaptchaFocus] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [showMsgOK, setShowMsgOK] = useState(false);
  const [showMsgErr, setShowMsgErr] = useState(false);

  const recaptcha = useRef();

  const handleSubmitMessage = async (event) => {
    event.preventDefault();
    const elements = event.target.elements;
    const token = recaptcha.current.getValue();

    if (!token) {
      setCaptchaFocus(true);
    } else {
      setCaptchaFocus(false);
      setOnSubmit(true);
      setShowMsgOK(false);
      setShowMsgErr(false);
      try {
        const response = await axios.post(
          API_ADDRESS,
          {
            name: elements["name"].value,
            email: elements["email"].value,
            message: elements["message"].value,
            token: token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.status === 200) {
          setShowMsgOK(true);
          document.getElementById("contact-form").reset();
        } else {
          setShowMsgErr(true);
        }
      } catch (error) {
        setShowMsgErr(true);
      }
      recaptcha.current.reset();
      setOnSubmit(false);
    }
  };

  return (
    <div className="section section-signup" id="contact-section">
      <Container>
        <div className="squares square-1" />
        <div className="squares square-2" />
        <div className="squares square-3" />
        <div className="squares square-4" />
        <Row className="row-grid justify-content-between align-items-center">
          <Col lg="6"> 
            <p className="text-white mb-3" style={{fontWeight:"700"}}> 
              Use the contact form to send messages to me , I will get back to
              you as soon as possible.
            </p> 
          </Col>
          <Col className="mb-lg-auto" lg="6">
            <Form id="contact-form" className="form" onSubmit={handleSubmitMessage}>
              <Card className="card-register">
                <CardHeader>
                  <CardImg
                    alt="..."
                    src={require("../assets/img/square-purple-1.png")}
                  />
                  <CardTitle tag="h4">Contact Me</CardTitle>
                </CardHeader>

                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": fullNameFocus,
                    })}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="tim-icons icon-single-02" />
                      </span>
                    </div>
                    <Input
                      name="name"
                      placeholder="Name"
                      type="text"
                      required
                      onFocus={(e) => setFullNameFocus(true)}
                      onBlur={(e) => setFullNameFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": emailFocus,
                    })}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="tim-icons icon-email-85" />
                      </span>
                    </div>
                    <Input
                      name="email"
                      placeholder="Email"
                      type="email"
                      required
                      onFocus={(e) => setEmailFocus(true)}
                      onBlur={(e) => setEmailFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": messageFocus,
                    })}
                  >
                    <Input
                      name="message"
                      placeholder="Type your message here..."
                      type="textarea"
                      required
                      onFocus={(e) => setMessagectFocus(true)}
                      onBlur={(e) => setMessagectFocus(false)}
                    />
                  </InputGroup>
                  <div
                    className={classnames({
                      "captcha-focus": captchaFocus,
                    })}
                  >
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={CAPTCHA_SITE_KEY}
                      onChange={(e) => setCaptchaFocus(false)}
                    />
                  </div>
                </CardBody>
                <CardFooter>
                  {onSubmit ? (
                    <div style={{ paddingLeft: "75px", paddingTop: "15px" }}>
                      <ReactLoading type="spin" height="32px" width="32px" />
                    </div>
                  ) : (
                    <Button
                      className="btn-round"
                      color="primary"
                      size="lg"
                      type="submit"
                    >
                      Send Message
                    </Button>
                  )}
                  {showMsgOK && (
                    <p style={{ color: "#00f2c3" }}>
                      Your message has been sent successfully!
                    </p>
                  )}{" "}
                  {showMsgErr && (
                    <p style={{ color: "#fd5d93" }}>
                      Sorry, there is a Problem, try again later!
                    </p>
                  )}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
