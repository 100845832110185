import React from "react";
import { Container, Row, Col } from "reactstrap";
import * as Constants from "../constants/index";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col>
            <p> {Constants.FOOTER_TEXT} </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
